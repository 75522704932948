import "../css/lib.css";
import "../css/main.css";
import "../css/normalize.css";
import "../style.css";
import {
  subQuestions
} from '../data'
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { RiseLoader } from 'react-spinners'
import {
  HeaderComponent,

} from "../components/index";
import * as queryString from "query-string";
import { useStyles } from "../style";

import { navigate } from "gatsby"
import axios from 'axios';
import { Snackbar } from "@material-ui/core";
 

 

const SubQuestions = () => {
  const classes = useStyles();
  const [question, setQuestion] = useState<any>({});
  const { soNum, phoneNum , orderType } = typeof window !== 'undefined' ? queryString.parse(window.location.search) : { soNum: '', phoneNum: ''  , orderType:0};
  const [hasError, setHasError] = useState<boolean>(false)
  const [displayTextBox , setDisplayTextBox] = useState<boolean>(false);
  const [content , setContent] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading , setLoading] = useState<boolean>(false);
  const [feedBackObjectType , setFeedBackObjectType] = useState(undefined)
  function sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const showErrorMsg = async (msg: string): Promise<void> => {
    setErrorMessage(undefined);
    await sleep(200);
    setErrorMessage(msg);
    await sleep(5000);
    setErrorMessage(undefined);
  };
  useEffect(() => {
    if(question?.khac) {
      setDisplayTextBox(true)
    }
    else {
      setDisplayTextBox(false)
    }
  } , [question])
  const selectQuestion = (value: any) => {
    console.log(question, 'question1')
    let answer = { [value['question']]: value['answer'] }
     setQuestion({ ...answer })

  }
  const handleGetContentFromBoxText = (e:any) => {
     setContent(e.target.value.trim())
  } 
  const handleSubmitOptionalQuestions = (questions: any) => {
    if (Object.keys(questions).length <= 0) {
      showErrorMsg('Phải chọn kênh bạn biết đến!')

      return;
    }

    const url = process.env.GATSBY_END_POINT + '/cust-feedback/v1/save-optional-question';
    let input: any = questions;

    if (typeof input === 'object' && input && input.khac) {
    input.khac = content;
    }
     if( input.khac && input?.khac.length <= 0) {
      showErrorMsg('Phải nhập nội dung!')
      return;
    }
    const data = JSON.stringify({
      "soNum": soNum,
      "phoneNum": phoneNum,
      "optionalQuestion": input
    });
    const config: Object = {
      method: 'post',
      maxBodyLength: Infinity,
      url: url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    setLoading(true);
    axios.request(config)
      .then((response) => {
        if (response.data.statusCode == 200) {
          navigate(`/?soNum=${soNum}&phoneNum=${phoneNum}`)

        }
        else {
          setHasError(true)
          setLoading(false);

        }
      })
      .catch((error) => {
        setHasError(true)
        setLoading(false);

      });

  }
  console.log(hasError, 'hasError')
  return (
    <main className={classes.body}>
      <Helmet
        title="Trang Sức Cao Cấp PNJ | Cty Cổ Phần Vàng Bạc Đá Quý Phú Nhuận"
        defer={false}
      />
      <HeaderComponent />
      {orderType == 2 || orderType == 3 ?
      
      <div className="body_v3">

        <div className="sub-question-container">
          <div className="thank-title-v3" style={{ "marginTop": "90px", "fontSize": "18px" }}>Quý khách biết đến PNJ qua kênh nào?</div>
          <div style={{ "margin": "0 30px"  }} >
            <div
              style={{ "display": "flex", "flexWrap": "wrap" ,  "margin": "10px 0" }}

            >
              {subQuestions && subQuestions.map((value: any) =>
                <div className={question.hasOwnProperty(value['question']) == true ? 'question-item-active' : 'question-item'}>
                  <div onClick={() => selectQuestion(value)} className="border-reason-v3 " >
                    <div className="caption-reason-v3">{value.title}</div>
                  </div>
                </div>
              )}
              
            </div>
            {displayTextBox && 
            <div>
               <textarea
                className="text-field-diff"
                style={{"backgroundColor":"#fff" , "color":"#000000"}}
                onChange={(e) => handleGetContentFromBoxText(e)}
                rows={4}
              />
            </div>
            }
              
          </div>
          {hasError == true ?

            <div className="thank-title">Vui lòng thử lại sau!</div>
            :
            <div
              onClick={() => {
                handleSubmitOptionalQuestions(question);
              }}
              style={{"color":"#212B36" , "backgroundColor":"#FBC533" , "borderRadius":"20px" , "border": "2px solid #cfb97f" , "padding":"6px 16px" , "cursor":"pointer" , "margin":"15px 0px"}}
              // color="primary"
            >
              Gửi phản hồi
            </div>

          }
        </div>

      </div>
      : 
      
      <div className="body">

      <div className="sub-question-container">
        <div className="thank-title" style={{ "marginTop": "90px", "fontSize": "18px" }}>Quý khách biết đến PNJ qua kênh nào?</div>
        <div style={{ "margin": "0 30px"  }} >
          <div
            style={{ "display": "flex", "flexWrap": "wrap" ,  "margin": "10px 0" }}

          >
            {subQuestions && subQuestions.map((value: any) =>
              <div className={question.hasOwnProperty(value['question']) == true ? 'question-item-active' : 'question-item'}>
                <div onClick={() => selectQuestion(value)} className="border-reason " >
                  <div className="caption-reason-optional-questions">{value.title}</div>
                </div>
              </div>


            )}
            
          </div>
          {displayTextBox && 
          <div>
             <textarea
              className="text-field-diff"
              style={{"backgroundColor":"#000000"}}
              onChange={(e) => handleGetContentFromBoxText(e)}
              rows={4}
            />
          </div>
          }
            
        </div>
        {hasError == true ?

          <div className="thank-title">Vui lòng thử lại sau!</div>
          :
           
          <div
            onClick={() => {
              handleSubmitOptionalQuestions(question);
            }}
            style={{"color":"#cfb97f" , "borderRadius":"20px" , "border": "2px solid #cfb97f" , "padding":"6px 16px" , "cursor":"pointer" , "margin":"15px 0px"}}
            color="primary"
          >
            Gửi phản hồi
          </div>

        }
      </div>

    </div>
      }
      
      {loading &&
              <div className={classes.overlayLoading}>
                <RiseLoader color="#fff" style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'height': '100vh' }} />
              </div>
            }
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={1000}
        message={errorMessage}
      ></Snackbar>
    </main>
  );
};

export default SubQuestions;
